<script lang="ts">
    import type { HTMLAttributes } from 'svelte/elements'

    import { concatClasses } from '../utils'

    interface Props extends HTMLAttributes<HTMLTextAreaElement> {
        class?: string
        disabled?: boolean
        id: string
        name?: string
        valid?: boolean
        invalid?: boolean
        placeholder?: string
        readonly?: boolean
        required?: boolean
        value: string | undefined
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any
    }

    let {
        class: classNames = '',
        disabled = false,
        id,
        name = undefined,
        valid = false,
        invalid = false,
        placeholder = undefined,
        readonly = false,
        required = false,
        value = $bindable(),
        ...rest
    }: Props = $props()

    const classes = $derived(
        concatClasses([
            'text-area',
            valid ? 'valid' : '',
            invalid ? 'invalid' : '',
            classNames,
        ])
    )
</script>

<textarea
    {id}
    {name}
    class={classes}
    {disabled}
    {placeholder}
    {readonly}
    {required}
    bind:value
    {...rest}
></textarea>

<style global lang="postcss">
    .text-area {
        appearance: none;
        display: block;
        width: 100%;
        padding: theme('spacing.3') theme('spacing.4');
        font-weight: theme('fontWeight.normal');
        font-size: theme('fontSize.base');
        line-height: 1.5;
        color: theme('colors.primary-800');
        border: theme('borderWidth.DEFAULT') solid theme('colors.gray-200');
        border-radius: theme('borderRadius.DEFAULT');
        background-position: right theme('spacing.4') center;
        background-size: theme('spacing.6');
        background-repeat: no-repeat;
        background-color: theme('colors.white');
    }

    .text-area[disabled],
    .text-area[readonly] {
        opacity: 0.5;
        pointer-events: none;
    }

    .text-area.valid {
        border-color: theme('colors.green.600');
        background-image: url("data:image/svg+xml,%0A%3Csvg fill='%2316a34a' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' /%3E%3C/svg%3E");
    }

    .text-area.invalid {
        border-color: theme('colors.red.600');
        background-image: url("data:image/svg+xml,%3Csvg fill='%23dc2626' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z' /%3E%3C/svg%3E");
    }
</style>
